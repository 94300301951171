<template>
    <my-layout class="claim_main">
        <template #body>
            <div class="header">
                <img @click="$router.push('/h5')" src="../../assets/img/h5/logo_h5.png"/>
                <div class="right">
                    <span @click="$router.push('/aboutUs_h5')">关于</span>
                    <div class="button" @click="handleLogin">登录/注册</div>
                </div>
            </div>
            <div class="body_table">
                <div class="claim_table">
                    <table>
                        <tr class="table_head">
                            <th class="column1" style="align-self: flex-end; margin-bottom: 14px">产品功能</th>
                            <th class="column2"><img class="table_head" src="../../assets/img/h5/vip_head.png"/></th>
                            <th class="column2"><img class="table_head" src="../../assets/img/h5/svip_head.png"/></th>
                        </tr>
                        <tr class="has_bg">
                            <td class="column1">无限制广告创意搜索查看</td>
                            <td class="column2"><img src="../../assets/img/home/has_claim.png"/></td>
                            <td class="column2"><img src="../../assets/img/home/has_claim.png"/></td>
                        </tr>
                        <tr>
                            <td class="column1">无限制竞品公司查看</td>
                            <td class="column2"><img src="../../assets/img/home/has_claim.png"/></td>
                            <td class="column2"><img src="../../assets/img/home/has_claim.png"/></td>
                        </tr>
                        <tr class="has_bg">
                            <td class="column1">竞品广告数据查看</td>
                            <td class="column2"><img src="../../assets/img/home/has_claim.png"/></td>
                            <td class="column2"><img src="../../assets/img/home/has_claim.png"/></td>
                        </tr>
                        <tr>
                            <td class="column1">竞品推广策略分析</td>
                            <td class="column2"><img src="../../assets/img/home/has_claim.png"/></td>
                            <td class="column2"><img src="../../assets/img/home/has_claim.png"/></td>
                        </tr>
                        <tr class="has_bg">
                            <td class="column1">公司/关键词监测</td>
                            <td class="column2"><span>VIP特权</span></td>
                            <td class="column2"><span>SVIP特权</span></td>
                        </tr>
                        <tr>
                            <td class="column1">数据导出</td>
                            <td class="column2"><span>VIP特权</span></td>
                            <td class="column2"><span>SVIP特权</span></td>
                        </tr>
                        <tr class="has_bg">
                            <td class="column1">默认子账号数量</td>
                            <td class="column2"><span>0</span></td>
                            <td class="column2"><span>1</span></td>
                        </tr>
                        <tr>
                            <td class="column1">价格</td>
                            <td class="column2"><span class="price">￥<span>4980</span>/年</span></td>
                            <td class="column2"><span class="price">￥<span>19800</span>/年</span></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="bottom">
                <div>Copyright© 2021-2024 上海凭安网络科技有限公司 AI竞投 aijingtou.com All Rights Reserved. </div>
                <div class="link" @click="toLink">沪ICP备12039960号-38</div>
            </div>
        </template>
    </my-layout>
</template>

<script>
  import {
    get_user_info
  } from '@/api/common'
  export default {
    name: "claim",
    methods: {
      handleLogin() {
        get_user_info().then(res => {
          if(res.data.result_code == 0){
            this.$router.push('/box/dataView');
          }
        })
      },
      toLink() {
        window.open('https://beian.miit.gov.cn/#/Integrated/index','_blank')
      },
    }
  }
</script>

<style scoped lang="scss">
.claim_main {
    .header {
        width: 100%;
        height: 170px;
        background-color: #ffffff;
        color: #1f81f8;
        font-size: 42px;
        display: flex;
        align-items: center;
        position: relative;
        img  {
            width: 377px;
            height: 111px;
            position: absolute;
            left: 58px;
        }
        .right {
            position: absolute;
            right: 58px;
            .button {
                width: 240px;
                height: 90px;
                background-color: #ffffff;
                border: solid 3px #1f81f8;
                border-radius: 50px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-left: 76px;
                font-size: 36px;
                &:active {
                    background-color: #f6f9fe;
                }
            }
        }

    }
    .body_table {
        background: url("../../assets/img/h5/banner_bg.png") no-repeat;
        background-size: contain;
        padding-top: 375px;
        background-color: #fff;
        .claim_table {
            border-radius: 6px;
            display: inline-block;
            margin-bottom: 203px;
            table {
                text-align: center;
                font-size: 36px;
                .table_head {
                    font-size: 42px;
                    color: #999999;
                    img {
                        width: 259px;
                        height: 132px;
                        position: relative;
                        top: 12px;
                    }
                }
                .has_bg {
                    background-color: #f4f7fc;
                }
                .column1 {
                    width: 447px;
                    text-align: left;
                    padding-left: 39px;
                }
                .column2 {
                    width: 296px;
                    position: relative;
                    right: 20px;
                }
                .price {
                    color: #ff6000;
                    span {
                        font-size: 60px;
                        font-weight: bold;
                    }
                }
                tr {
                    min-height: 153px;
                    display: flex;
                    align-items: center;
                    img {
                        width: 60px;
                        height: 60px;
                    }
                }

            }
        }
    }
    .bottom {
        width: 100%;
        height: 229px;
        text-align: center;
        color: #999999;
        font-size: 36px;
        line-height: 60px;
        margin-top: 67px;
    }
}
</style>
